<template>
<b-container class="reports-other-ladger-tab-guide-transfer">
  <i18n :path="translationPath+'intro_p1'" tag="p" class="text-gray col-md-8 pl-0">
  </i18n>
  <b-form-group
    :label="$t(translationPath + 'asset')"
    label-cols-md="4"
    label-class="pt-3 font-weight-bold"
  >
    <custom-select
      :class="accountMainValidationCalss"
      :options="accountMainOptions"
      grouping-values="accounts"
      grouping-label="label"
      :loading="loadingAccountMainOptions"
      :label="'label'"
      v-model="accountMain"
      id="assetsSelection"
      :placeholder="$t('common.select_account')"
      :search-placeholder="$t('common.type_to_filter')"
      :noResultText="$t(translationPath + 'wizard.no_more_items')"
      @input="onDataInput"
    >
    </custom-select>
  </b-form-group>

  <b-form-group
    :label="$t(translationPath + 'amount')"
    label-cols-md="4"
    label-for="amountValue"
    label-class="pt-3 font-weight-bold"
  >
    <currency-input id="amountValue" v-model="amount" @input="onDataInput" />
    <b-form-text>
      <i18n :path="translationPath+'amount_description'" tag="span" class="text-gray">
        <b-link slot="asset_overview" to="/reports/other/account-plan-v2/assets">{{$t(translationPath+'amount_description_asset_overview')}}</b-link>
      </i18n>
    </b-form-text>
  </b-form-group>
</b-container>
</template>

<script>
import { mapState } from 'vuex'
import CurrencyInput from '@/components/common/CurrencyInput'
import CustomSelect from '@/components/common/CustomSelect'
import { required, minValue } from 'vuelidate/lib/validators'

export default {
  name: 'TabGuideTransferNetworthToAsset',
  components: { CurrencyInput, CustomSelect },
  props: {
    loadingAccountMainOptions: {
      type: Boolean,
      default: false
    },
    accountMainOptions: {
      type: Array,
      default: () => []
    },
    transaction: {
      type: Object,
      default: () => {}
    },
    highlightValidation: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    amount: {
      required,
      minValue: minValue(0)
    },
    accountMain: {
      required
    },
    form: ['amount', 'accountMain']
  },
  data () {
    return {
      translationPath: 'transactions.create.wizard.networth_to_asset.',

      accountMain: null,
      activePercentage: 0,
      amount: 0,
      clonedData: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    finalEntries () {
      return [
        {
          account_id: this.accountMain ? this.accountMain.id : null,
          offset_account_id: this.currentCOA.networth_account_id,
          active_percentage: this.activePercentage,
          amount: this.amount,
          dctype: 'dr'
        },
        {
          account_id: this.currentCOA.networth_account_id,
          offset_account_id: this.accountMain ? this.accountMain.id : null,
          active_percentage: this.activePercentage,
          amount: this.amount,
          dctype: 'cr'
        }
      ]
    },
    amountValidationClass () {
      if (this.highlightValidation && this.$v.amount.$error) return 'is-invalid'
      return ''
    },
    accountMainValidationCalss () {
      if (this.highlightValidation && this.$v.accountMain.$error && !this.loadingAccountMainOptions) return 'is-invalid'
      return ''
    }
  },
  methods: {
    setAccountMainValue (accountId) {
      this.accountMainOptions.forEach((group) => {
        group.accounts.forEach((account) => {
          if (account.id === accountId) {
            this.accountMain = account
          }
        })
      })
    },
    setInitialData (initSet = null) {
      this.clonedData = initSet || false
      if (this.clonedData) {
        this.amount = this.clonedData.amount
        if (this.clonedData.accountMainValue) {
          this.setAccountMainValue(this.clonedData.accountMainValue.id)
        }
        this.emit()
      } else {
        if (Object.prototype.hasOwnProperty.call(this.transaction, 'entries') && this.transaction.entries[0].amount) {
          this.amount = parseFloat(this.transaction.entries[0].amount)
        }
        if (Object.prototype.hasOwnProperty.call(this.transaction, 'entries') && this.transaction.entries[0].active_percentage) {
          this.activePercentage = parseInt(this.transaction.entries[0].active_percentage)
        }
        this.setInitialDataAccount()
      }
    },
    setInitialDataAccount () {
      this.accountMain = null
      this.$v.accountMain.$touch()

      if (Object.prototype.hasOwnProperty.call(this.transaction, 'entries')) {
        this.transaction.entries.forEach((item) => {
          if (item.dctype === 'dr' && Object.prototype.hasOwnProperty.call(item, 'account')) {
            this.setAccountMainValue(item.account_id)
          }
        })
      }
      this.emit()
    },
    onDataInput () {
      this.$v.form.$touch()
      this.$emit('on-validate', !this.$v.form.$error)
      this.$emit('on-data-input', this.finalEntries)
    },
    emit () {
      this.onDataInput()
    }
  },
  created () {
    this.setInitialData()
  },
  watch: {
    loadingAccountMainOptions () {
      if (!this.clonedData) {
        this.setInitialDataAccount()
      } else {
        if (typeof this.clonedData.accountMainValue !== 'undefined' && this.clonedData.accountMainValue) {
          this.setAccountMainValue(this.clonedData.accountMainValue.id)
        }
      }
    },
    transaction () {
      this.setInitialData()
    }
  }
}
</script>
