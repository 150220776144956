<template>
  <b-container class="reports-other-ledger-view bg-light page-wrapper">
    <b-container class="main-content-wrapper">

      <b-row no-gutters>
        <div class="breadCrumbs">
          <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        </div>
      </b-row>

      <!-- PAGE TITLE BLOCK -->
      <b-row no-gutters>
        <b-col class="col-md-12 col-lg-8 col-xl-8">
          <h1>{{ Object.keys(transaction).length > 0 ? $t('common.transaction') + ': "' + transaction.title + '"' : $t(translationPath + 'create_transaction') }}</h1>
        </b-col>
        <b-col class="col-lg-4 col-xl-4 col-md-12 d-none d-lg-block d-xl-block" align-self="center">
          <template v-if="Object.keys(transaction).length > 0">
            <!-- BUTTONS -->
            <b-button v-if="Object.keys(transaction).length > 0" class="btn-sm float-right" variant="outline-secondary" @click="$refs.singleDeleteTransModel.show()">
              <i class="flaticon stroke text-danger trash-2"></i> {{ $t('common.delete_transaction') }}
            </b-button>
            <b-button class="btn-sm float-right mr-2" variant="outline-primary" @click="cloneTransactionHandler">
              <i class="flaticon stroke copy-3"></i>
              {{ $t(translationPath+"clone_transaction") }}
            </b-button>
          </template>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col cols="12" class="col-lg-10 col-xl-8" >
          <span v-if="Object.keys(transaction).length > 0" class="lead">

          </span>
          <div v-else class="lead sidebar-text">
            <i18n :path="translationPath+'titles_and_descriptions.intro_p1'" tag="p">
              <b-link slot="import_account_statement" to="/transactions/import/account-statement/">{{$t(translationPath+'titles_and_descriptions.intro_p1_import_account_statement')}}</b-link>
              <b-link slot="add_multiple_transactions" to="/transactions/create/multiple">{{$t(translationPath+'titles_and_descriptions.intro_p1_add_multiple')}}</b-link>
              <b-link slot="upload_excel" to="/transactions/import/excel">{{$t(translationPath+'titles_and_descriptions.intro_p1_import_excel')}}</b-link>
              <b-link slot="read_more" target="_blank" href="https://forum.spirecta.se/t/skapa-en-transaktion-manuellt-de-olika-typerna-av-debet-och-kredit-transaktioner/99">{{ $t('common.read_in_manual') }}</b-link>
            </i18n>
          </div>
        </b-col>
      </b-row>

      <!-- Transaction details form -->
      <div class="wrapper_white_with_border py-4 mb-4">
        <b-row no-gutters>
          <b-col cols="12">
            <b-form @submit="onSubmit">
              <b-card class="mb-3" header-tag="header">

                <template v-slot:header>
                  <h3 class="mb-0">{{ $t(translationPath + 'labels.transaction_details') }}</h3>
                </template>

                <b-row no-gutters>

                  <b-col cols="12" md="4">
                    <b-form-group id="example-input-group-2" :label="$t(translationPath + 'labels.transaction_date')"
                                  label-for="example-input-2" :state="transactionFormDateState">
                      <datepicker
                        id="example-input-2"
                        name="example-input-2"
                        v-model="transaction_form.date"
                        input-class="form-control input-date"
                        :typeable="true"
                        :required="true"
                        :format="'yyyy-MM-dd'"
                        :placeholder="'YYYY-MM-DD'"
                        :state="transactionFormDateState"
                        :invalid-feedback="invalidDateFeed"
                        :language="selectedLang"
                        aria-describedby="input-2-live-feedback"
                        :monday-first="startWeekByMonday"
                      ></datepicker>
                      <b-form-invalid-feedback id="input-2-live-feedback">
                        {{ $t(translationPath + 'required_valid_date') }}
                      </b-form-invalid-feedback>
                      <b-form-text>{{$t(translationPath + 'labels.transaction_date_description')}}</b-form-text>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="8" class="pl-md-3">
                    <b-form-group id="example-input-group-1" :label="$t(translationPath + 'labels.transaction_title')"
                                  label-for="example-input-1">
                      <b-form-input
                        id="example-input-1"
                        name="example-input-1"
                        v-model="$v.transaction_form.title.$model"
                        :state="$v.transaction_form.title.$dirty ? !$v.transaction_form.title.$error : null"
                        aria-describedby="input-1-live-feedback"
                      ></b-form-input>
                      <b-form-invalid-feedback id="input-1-live-feedback">
                        {{ $t(translationPath + 'title_required') }}
                      </b-form-invalid-feedback>
                      <b-form-text>{{$t(translationPath + 'labels.transaction_title_description')}}</b-form-text>
                    </b-form-group>
                  </b-col>

                </b-row>

                <b-row no-gutters>
                  <b-col cols="12" md="6" class="pr-3" v-show="this.entries.length <= 2">
                    <b-form-group id="example-input-group-4" :label="$t(translationPath + 'labels.label_guide')"
                                  label-for="example-input-5">
                      <b-form-select
                        id="example-input-5"
                        name="example-input-5"
                        v-model="selectedGuideTab"
                        :options="guideTabOptions"
                        :state="$v.selectedGuideTab.$dirty ? !$v.selectedGuideTab.$error : null"
                      ></b-form-select>
                      <b-form-invalid-feedback id="input-5-live-feedback">
                        {{ $t(translationPath + 'guide_required') }}
                      </b-form-invalid-feedback>
                      <b-form-text>{{$t(translationPath + 'labels.transaction_guide_description')}}</b-form-text>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6" class="pr-3" v-show="isTransactionTypeDropdownShown">
                    <b-form-group id="example-input-group-4" :label="$t(translationPath + 'transaction_type')"
                                  label-for="example-input-4">
                      <b-form-select
                        id="example-input-4"
                        :options="transactionTypeOptions"
                        name="example-input-4"
                        v-model="$v.transaction_form.type.$model"
                        :state="$v.transaction_form.type.$dirty ? !$v.transaction_form.type.$error : null"
                        aria-describedby="input-3-live-feedback"
                      ></b-form-select>
                    </b-form-group>
                  </b-col>

                  <b-col cols="12" md="6">
                    <b-form-group id="example-input-group-3"
                                  :label="$t(translationPath + 'labels.transaction_description')"
                                  label-for="example-input-3">
                      <b-form-textarea
                        id="example-input-3"
                        name="example-input-3"
                        v-model="$v.transaction_form.description.$model"
                        :state="$v.transaction_form.description.$dirty ? !$v.transaction_form.description.$error : null"
                        aria-describedby="input-3-live-feedback"
                      ></b-form-textarea>
                      <b-form-text>{{$t(translationPath + 'labels.transaction_description_description')}}</b-form-text>
                    </b-form-group>
                  </b-col>
                </b-row>

              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </div>

      <!-- Second part of page -->

        <b-row class="wrapper_white_with_border py-4 mb-4" no-gutters v-if="selectedGuideTab">
          <b-col cols="12">
            <b-card no-body class="pb-3">
              <b-tabs card ref="tabs">

                <b-tab v-if="selectedGuideTab === 'asset_value_decrease_networth_account' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.asset_value_decrease_networth_account')" ref="BTabGuideAssetValueDecreaseNetworthAccount"
                       @click="onAssetValueDecreaseNetworthAccountTabClick">
                  <tab-guide-asset-value-decrease-networth-account
                    ref="TabGuideAssetValueDecreaseNetworthAccount"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'asset_value_decrease_networth_account')"
                    @on-validate="(value) => onValidate(value, 'asset_value_decrease_networth_account')"
                  ></tab-guide-asset-value-decrease-networth-account>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'asset_value_decrease' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.asset_value_decrease')" ref="BTabGuideAssetValueDecrease"
                       @click="onAssetValueDecreaseTabClick">
                  <tab-guide-asset-value-decrease
                    ref="TabGuideAssetValueDecrease"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'asset_value_decrease')"
                    @on-validate="(value) => onValidate(value, 'asset_value_decrease')"
                  ></tab-guide-asset-value-decrease>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'expense_credit' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.expense_credit')" ref="BTabGuideExpenseCredit"
                       @click="onExpenseCreditTabClick">
                  <tab-guide-expense-credit
                    ref="TabGuideExpenseCredit"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'expense_credit')"
                    @on-validate="(value) => onValidate(value, 'expense_credit')"
                  ></tab-guide-expense-credit>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'expense_debit' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.expense_debit')" ref="BTabGuideExpenseDebit"
                       @click="onExpenseDebitTabClick">
                  <tab-guide-expense-debit
                    ref="TabGuideExpenseDebit"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'expense_debit')"
                    @on-validate="(value) => onValidate(value, 'expense_debit')"
                  ></tab-guide-expense-debit>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'expense_networth' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.expense_networth')" ref="BTabGuideExpenseNetworth"
                       @click="onExpenseNetworthTabClick">
                  <tab-guide-expense-networth
                    ref="TabGuideExpenseNetworth"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'expense_networth')"
                    @on-validate="(value) => onValidate(value, 'expense_networth')"
                  ></tab-guide-expense-networth>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'income' && !onlyAdvanced" :title="$t('common.deposit')"
                       ref="BTabGuideIncome" @click='onIncomeTabClick'>
                  <tab-guide-income
                    ref="TabGuideIncome"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'income')"
                    @on-validate="(value) => onValidate(value, 'income')"
                  ></tab-guide-income>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'income_asset_value_increase_capital_account' && !onlyAdvanced" :title="$t(translationPath + 'guide.income_asset_value_increase_capital_account')"
                       ref="BTabGuideIncomeAssetValueIncreaseCapitalAccount" @click='onIncomeAssetValueIncreaseCapitalAccountTabClick'>
                  <tab-guide-income-asset-value-increase-capital-account
                    ref="TabGuideIncomeAssetValueIncreaseCapitalAccount"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'income_asset_value_increase_capital_account')"
                    @on-validate="(value) => onValidate(value, 'income_asset_value_increase_capital_account')"
                  ></tab-guide-income-asset-value-increase-capital-account>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'income_to_liability' && !onlyAdvanced" :title="$t(translationPath + 'guide.income_to_liability')"
                       ref="BTabGuideIncomeToLiability" @click='onIncomeToLiabilityTabClick'>
                  <tab-guide-income-to-liability
                    ref="TabGuideIncomeToLiability"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'income_to_liability')"
                    @on-validate="(value) => onValidate(value, 'income_to_liability')"
                  ></tab-guide-income-to-liability>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'income_networth' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.income_networth')" ref="BTabGuideIncomeNetworth"
                       @click='onIncomeNetworthTabClick'>
                  <tab-guide-income-networth
                    ref="TabGuideIncomeNetworth"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'income_networth')"
                    @on-validate="(value) => onValidate(value, 'income_networth')"
                  ></tab-guide-income-networth>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'liability_amortization' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.liability_amortization')"
                       ref="BTabGuideLiabilityAmortization" @click="onLiabilityAmortizationTabClick">
                  <tab-guide-liability-amortization
                    ref="TabGuideLiabilityAmortization"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'liability_amortization')"
                    @on-validate="(value) => onValidate(value, 'liability_amortization')"
                  ></tab-guide-liability-amortization>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'liability_amortization_networth_account' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.liability_amortization_networth_account')"
                       ref="BTabGuideLiabilityAmortizationNetworth" @click="onLiabilityAmortizationNetworthTabClick">
                  <tab-guide-liability-amortization-networth
                    ref="TabGuideLiabilityAmortizationNetworth"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'liability_amortization_networth_account')"
                    @on-validate="(value) => onValidate(value, 'liability_amortization_networth_account')"
                  ></tab-guide-liability-amortization-networth>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'transfer_asset_to_asset' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.transfer_asset_to_asset')"
                       ref="BTabGuideTransferAssetToAsset" @click="onTransferAssetToAssetTabClick">
                  <tab-guide-transfer-asset-to-asset
                    ref="TabGuideTransferAssetToAsset"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'transfer_asset_to_asset')"
                    @on-validate="(value) => onValidate(value, 'transfer_asset_to_asset')"
                  ></tab-guide-transfer-asset-to-asset>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'transfer_liability_to_liability' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.transfer_liability_to_liability')"
                       ref="BTabGuideTransferLiabilityToLiability" @click="onTransferLiabilityToLiabilityTabClick">
                  <tab-guide-transfer-liability-to-liability
                    ref="TabGuideTransferLiabilityToLiability"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'transfer_liability_to_liability')"
                    @on-validate="(value) => onValidate(value, 'transfer_liability_to_liability')"
                  ></tab-guide-transfer-liability-to-liability>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'transfer_liability_to_asset' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.transfer_liability_to_asset')"
                       ref="BTabGuideTransferLiabilityToAsset" @click="onTransferLiabilityToAssetTabClick">
                  <tab-guide-transfer-liability-to-asset
                    ref="TabGuideTransferLiabilityToAsset"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'transfer_liability_to_asset')"
                    @on-validate="(value) => onValidate(value, 'transfer_liability_to_asset')"
                  ></tab-guide-transfer-liability-to-asset>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'transfer_networth_to_asset' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.transfer_networth_to_asset')"
                       ref="BTabGuideTransferNetworthToAsset" @click="onTransferNetworthToAssetTabClick">
                  <tab-guide-transfer-networth-to-asset
                    ref="TabGuideTransferNetworthToAsset"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'transfer_networth_to_asset')"
                    @on-validate="(value) => onValidate(value, 'transfer_networth_to_asset')"
                  ></tab-guide-transfer-networth-to-asset>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'transfer_networth_to_liability' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.transfer_networth_to_liability')"
                       ref="BTabGuideTransferNetworthToLiability" @click="onTransferNetworthToLiabilityTabClick">
                  <tab-guide-transfer-networth-to-liability
                    ref="TabGuideTransferNetworthToLiability"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'transfer_networth_to_liability')"
                    @on-validate="(value) => onValidate(value, 'transfer_networth_to_liability')"
                  ></tab-guide-transfer-networth-to-liability>
                </b-tab>

                <b-tab v-if="selectedGuideTab === 'transfer_refund' && !onlyAdvanced"
                       :title="$t(translationPath + 'guide.transfer_refund')" ref="BTabGuideTransferRefund"
                       @click="onTransferRefundTabClick">
                  <tab-guide-transfer-refund
                    ref="TabGuideTransferRefund"
                    :loadingAccountMainOptions="loadingMainAccounts"
                    :loadingAccountOffsetOptions="loadingOffsetAccounts"
                    :accountMainOptions="mainAccountTranslatedOptions"
                    :accountOffsetOptions="offsetAccountTranslatedOptions"
                    :transaction="transaction"
                    :highlightValidation="isAnyError"
                    @on-data-input="(value) => onTabInput(value, 'transfer_refund')"
                    @on-validate="(value) => onValidate(value, 'transfer_refund')"
                  ></tab-guide-transfer-refund>
                </b-tab>

                <!-- Advanced tab -->
                <b-tab
                  v-if="selectedGuideTab === 'complex_transaction' || Object.prototype.hasOwnProperty.call($route.params, 'transaction_id')"
                  :title="$t(translationPath + 'guide.manual')" ref="BTabAdvanced" id="advancedTab" class="px-0 py-0"
                  @click="onAdvancedTabClick">
                  <tab-advanced
                    ref="TabAdvanced"
                    :transaction="transaction"
                    :accountOptions="mainAccountOptions"
                    @on-data-input="(value) => onTabInput(value, 'advanced')"
                    @on-validate="(value) => onValidate(value, 'advanced')"
                    @on-edit-mode-changed="onAdvancedEditModeChanged"
                  ></tab-advanced>
                </b-tab>

                <!-- Misc -->
                <b-tab
                  id ="miscTab"
                  :title="$t(translationPath + 'guide.misc')"
                >
                  <tab-misc
                    :transaction="transaction"
                    @transaction-updated="onMiscTabTransactionUpdated"
                    @tags-updated="onMiscTabTagsUpdated"
                  />
                </b-tab>
              </b-tabs>
            </b-card>
          </b-col>
        </b-row>

      <!-- Save transaction footer -->
      <b-row class="wrapper_white_with_border" no-gutters v-if="selectedGuideTab">
        <b-col cols="12">
          <b-card no-body class=" mt-3 py-3" style="background: none;">
            <div v-if="!isAdvancedTabBalanced && activeTab === 'advanced'">
              <b-row>
                <b-col class="pl-3 pr-3">
                  <b-alert :show="disableSaveButton" variant="danger" class="mb-0 text-center">
                    {{$t(translationPath + 'cannot_save')}}
                  </b-alert>
                </b-col>
              </b-row>
            </div>
            <div>
              <b-row class="justify-content-md-center">
                <b-col md="auto">
                  <button class='btn btn-primary px-4 d-block' :disabled="disableSaveButton || isAdvancedTabEditMode" @click="saveTransactions">
                    {{$t(translationPath + 'save_transaction')}}
                  </button>
                </b-col>
              </b-row>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <delete-transaction-modal
        ref="singleDeleteTransModel"
        :itemName="Object.prototype.hasOwnProperty.call(transaction, 'title') ? transaction.title : ''"
        @on-delete-confirm="deleteCurrentTransaction"
      ></delete-transaction-modal>

    </b-container>

    <success-modal
      :title="$t(translationPath + 'success_modal.title').toString()"
      :message="$t(translationPath + 'success_modal.message', {transaction: transaction_form.title}).toString()"
      :button="sSuccessModalButtonText"
      :redirect="sSuccessModalRedirectUrl"
      :orLink="'/redirect?to=ledgerCreate'"
      :orLinkText="$t(translationPath + 'success_modal.or_link_text').toString()"
      @hide="onSuccessModalHide"
    ></success-modal>
  </b-container>
</template>

<script>
import axios from 'axios'
import TabAdvanced from './inc/TabAdvanced'
import TabGuideAssetValueDecreaseNetworthAccount from './inc/TabGuideAssetValueDecreaseNetworthAccount'
import TabGuideAssetValueDecrease from '@/views/transactions/create/inc/TabGuideAssetValueDecrease'
import TabGuideIncomeAssetValueIncreaseCapitalAccount from './inc/TabGuideIncomeAssetValueIncreaseCapitalAccount'
import TabGuideIncomeToLiability from './inc/TabGuideIncomeToLiability'
import TabGuideExpenseCredit from './inc/TabGuideExpenseCredit'
import TabGuideExpenseDebit from './inc/TabGuideExpenseDebit'
import TabGuideExpenseNetworth from './inc/TabGuideExpenseNetworth'
import TabGuideIncome from './inc/TabGuideIncome'
import TabGuideIncomeNetworth from './inc/TabGuideIncomeNetworth'
import TabGuideLiabilityAmortization from './inc/TabGuideLiabilityAmortization'
import TabGuideLiabilityAmortizationNetworth from './inc/TabGuideLiabilityAmortizationNetworth'
import TabGuideTransferAssetToAsset from './inc/TabGuideTransferAssetToAsset'
import TabGuideTransferLiabilityToAsset from './inc/TabGuideTransferLiabilityToAsset'
import TabGuideTransferLiabilityToLiability from './inc/TabGuideTransferLiabilityToLiability'
import TabGuideTransferNetworthToAsset from './inc/TabGuideTransferNetworthToAsset'
import TabGuideTransferNetworthToLiability from './inc/TabGuideTransferNetworthToLiability'
import TabGuideTransferRefund from './inc/TabGuideTransferRefund'
import TabMisc from './inc/TabMisc'

import { mapState } from 'vuex'
import formatDate from '@/assets/filters/formatDate'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import { validationMixin } from 'vuelidate'
import { required, minLength } from 'vuelidate/lib/validators'
import Datepicker from 'vuejs-datepicker'
import { en, da, sv } from 'vuejs-datepicker/dist/locale'
import titleMixins from '@/mixins/title'
import DeleteTransactionModal from '@/components/modals/DeleteTransactionModal'
import SuccessModal from '@/components/modals/SuccessModal'

export default {
  name: 'viewTransaction',
  mixins: [validationMixin, titleMixins],
  components: {
    Datepicker,
    DeleteTransactionModal,
    SuccessModal,
    TabAdvanced,
    TabGuideAssetValueDecreaseNetworthAccount,
    TabGuideIncomeAssetValueIncreaseCapitalAccount,
    TabGuideIncomeToLiability,
    TabGuideAssetValueDecrease,
    TabGuideExpenseCredit,
    TabGuideExpenseDebit,
    TabGuideExpenseNetworth,
    TabGuideIncome,
    TabGuideIncomeNetworth,
    TabGuideLiabilityAmortization,
    TabGuideLiabilityAmortizationNetworth,
    TabGuideTransferAssetToAsset,
    TabGuideTransferLiabilityToAsset,
    TabGuideTransferLiabilityToLiability,
    TabGuideTransferNetworthToAsset,
    TabGuideTransferNetworthToLiability,
    TabGuideTransferRefund,
    TabMisc
  },
  data () {
    return {
      startWeekByMonday: Object.prototype.hasOwnProperty.call(process.env, 'VUE_APP_DATEPICKER_START_DAY_BY_MONDAY') && process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY ? process.env.VUE_APP_DATEPICKER_START_DAY_BY_MONDAY === 'true' : false,
      transaction: {},
      entries: [],
      transaction_form: {
        title: '',
        date: '',
        description: '',
        type: '',
        verified: '0',
        tags: []
      },
      mainAccountOptions: [],
      loadingMainAccounts: true,
      loadingOffsetAccounts: true,
      accountType: null,
      offsetAccountOptions: [],
      isTransactionTypeDropdownShown: false, // Todo: remove it totally with input field
      activeTab: '',
      selectedGuideTab: '',
      isTabValid: false,
      isDetailsValid: false,
      dirty: false,
      isAdvancedTabBalanced: false,
      isAdvancedTabEditMode: false,
      translationPath: 'transactions.create.',
      sSuccessModalButtonText: this.$t('transactions.create.success_modal.button1'),
      sSuccessModalRedirectUrl: '/transactions/view',
      datepickerLang: {
        en: en,
        sv: sv,
        da: da
      }
    }
  },
  async created () {
    if (!Object.prototype.hasOwnProperty.call(this.$route.params, 'transaction_id') || this.$route.params.transaction_id <= 0) {
      this.transaction_form.date = new Date().toISOString().substr(0, 10)
    }

    await this.fetchTransactions()
    this.loadingOffsetAccounts = true
    const offsetAccountType = this.offsetAccountOnType(this.transaction.subtype ?? null)
    this.fetchAccountGroups(offsetAccountType)
      .then(data => {
        this.offsetAccountOptions = data
      })
      .catch(err => {
        console.error(err)
        this.offsetAccountOptions = []
      })
      .then(() => {
        this.loadingOffsetAccounts = false
      })
  },
  validations: {
    transaction_form: {
      title: { required, minLength: minLength(3) },
      date: { required },
      description: {},
      type: {}
    },
    selectedGuideTab: { required }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    loading () {
      let loading = true
      switch (this.selectedGuideTab) {
        case 'transfer_networth_to_liability':
        case 'transfer_networth_to_asset':
        case 'income_networth':
        case 'expense_networth':
          loading = this.loadingMainAccounts
          break
        case 'liability_amortization_networth_account':
          loading = this.loadingOffsetAccounts
          break
        default:
          loading = this.loadingMainAccounts || this.loadingOffsetAccounts
          break
      }

      return loading
    },
    selectedLang () {
      return this.datepickerLang[this.$i18n.locale]
    },
    breadcrumbItems () {
      if (!this.loading) {
        return [
          { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/import/all' },
          (Object.prototype.hasOwnProperty.call(this.transaction, 'date') ? { text: this.$options.filters.formatDate(this.transaction.date, 'sv-SE'), to: `/transactions/view?start_date=${this.$options.filters.formatDate(this.transaction.date)}&end_date=${this.$options.filters.formatDate(this.transaction.date)}` } : { text: this.$t(this.translationPath + 'create'), to: '/transactions/create/single' }),
          { text: 'Account', active: true }
        ]
      } else {
        return [
          { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/import/all' },
          { text: '', active: true }
        ]
      }
    },
    disableSaveButton () {
      return this.dirty && (!this.isTabValid || !this.isDetailsValid)
    },
    isAnyError () {
      return this.dirty && this.disableSaveButton && !this.loading
    },
    disabledDates () {
      const currentDate = new Date()
      return { from: new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1) }
    },
    transactionFormDateState () {
      if (this.$v.transaction_form.date.$dirty && !this.$v.transaction_form.date.required) {
        return false
      } else if (this.$v.transaction_form.date.$dirty && !this.$v.transaction_form.date.validDateNotFuture) {
        return false
      } else {
        return null
      }
    },
    transactionTypeOptions () {
      return [
        { value: 'deposit', text: this.$t('common.transaction_types.deposit') },
        { value: 'withdrawal', text: this.$t('common.transaction_types.withdrawal') },
        { value: 'transfer', text: this.$t('common.transaction_types.transfer') }
      ]
    },
    invalidDateFeed () {
      if (this.$v.transaction_form.date.$dirty && !this.$v.transaction_form.date.required) {
        return this.$t('common.required')
      } else if (this.$v.transaction_form.date.$dirty && !this.$v.transaction_form.date.validDateNotFuture) {
        return this.$t('common.future_dates_not_allowed')
      } else {
        return ''
      }
    },
    titleMeta () {
      return Object.keys(this.transaction).length > 0 ? this.$t('common.transaction') + ': "' + this.transaction.title + '"' : this.$t('transactions.import._common.add_single_transaction')
    },
    mainAccountTranslatedOptions () {
      return this.getTranslatedAccountOptions(this.mainAccountOptions)
    },
    offsetAccountTranslatedOptions () {
      return this.getTranslatedAccountOptions(this.offsetAccountOptions)
    },
    guideTabOptions () {
      return [
        {
          label: this.$t('common.incomes'),
          options: [
            { value: 'income', text: this.$t(this.translationPath + 'guide.income') },
            { value: 'income_to_liability', text: this.$t(this.translationPath + 'guide.income_to_liability') },
            { value: 'income_networth', text: this.$t(this.translationPath + 'guide.income_networth') }
          ]
        },
        {
          label: this.$t('common.expenses'),
          options: [
            { value: 'expense_debit', text: this.$t(this.translationPath + 'guide.expense_debit') },
            { value: 'expense_credit', text: this.$t(this.translationPath + 'guide.expense_credit') },
            { value: 'expense_networth', text: this.$t(this.translationPath + 'guide.expense_networth') },
            { value: 'transfer_refund', text: this.$t(this.translationPath + 'guide.transfer_refund') }
          ]
        },
        {
          label: this.$t('common.assets'),
          options: [
            { value: 'transfer_asset_to_asset', text: this.$t(this.translationPath + 'guide.transfer_asset_to_asset') },
            { value: 'transfer_networth_to_asset', text: this.$t(this.translationPath + 'guide.transfer_networth_to_asset') },
            { value: 'income_asset_value_increase_capital_account', text: this.$t(this.translationPath + 'guide.income_asset_value_increase_capital_account') },
            { value: 'asset_value_decrease_networth_account', text: this.$t(this.translationPath + 'guide.asset_value_decrease_networth_account') },
            { value: 'asset_value_decrease_capital_account', text: this.$t(this.translationPath + 'guide.asset_value_decrease_capital_account') },
            { value: 'asset_value_decrease', text: this.$t(this.translationPath + 'guide.asset_value_decrease') }
          ]
        },
        {
          label: this.$t('common.liabilities'),
          options: [
            { value: 'liability_amortization', text: this.$t(this.translationPath + 'guide.liability_amortization') },
            { value: 'transfer_liability_to_asset', text: this.$t(this.translationPath + 'guide.transfer_liability_to_asset') },
            { value: 'transfer_liability_to_liability', text: this.$t(this.translationPath + 'guide.transfer_liability_to_liability') },
            { value: 'transfer_networth_to_liability', text: this.$t(this.translationPath + 'guide.transfer_networth_to_liability') },
            { value: 'liability_amortization_networth_account', text: this.$t(this.translationPath + 'guide.liability_amortization_networth_account') }
          ]
        },
        {
          label: this.$t(this.translationPath + 'guide.transactions_other'),
          options: [
            { value: 'complex_transaction', text: this.$t(this.translationPath + 'guide.complex_transaction') }
          ]
        }
      ]
    },
    onlyAdvanced () {
      if (this.entries.length > 2 || this.selectedGuideTab === 'complex_transaction') {
        return true
      }
      return false
    }
  },
  methods: {
    offsetAccountOnType (type) {
      let offsetAccountOptionsType = 'asset,liability'
      if (type === 'income' || type === 'expense_debit' || type === 'transfer_asset_to_asset' || type === 'transfer_liability_to_asset' || type === 'income_asset_value_increase_capital_account') {
        offsetAccountOptionsType = 'asset'
      } else if (type === 'transfer_liability_to_liability' || type === 'liability_amortization_networth_account' || type === 'expense_credit') {
        offsetAccountOptionsType = 'liability'
      } else if (type === 'asset_value_decrease') {
        offsetAccountOptionsType = 'income_capital_accounts'
      }
      return offsetAccountOptionsType
    },
    cloneTransactionHandler () {
      this.transaction.type = this.transaction.type || this.transaction_form.type
      this.$router.push({
        name: 'transactions-create',
        params: {
          clone: this.transaction
        }
      })
    },
    setInitialValuesOnType (transaction) {
      this.transaction_form = {
        title: transaction.title,
        date: new Date(transaction.date),
        description: transaction.description,
        type: transaction.type,
        verified: transaction.verified
      }
      this.selectedGuideTab = transaction.transaction_subtype
      const entries = transaction.entries || []
      let crAccount = null
      let drAccount = null
      let transactionIncomeAmount = []
      const transactionSubtype = transaction.subtype || ''
      if (entries.length === 1 || entries.length === 2) {
        if (['income', 'transfer_refund', 'asset_value_decrease_networth_account', 'transfer_liability_to_asset', 'asset_value_decrease', 'transfer_liability_to_liability', 'income_networth', 'liability_amortization_networth_account', 'transfer_networth_to_liability', 'transfer_asset_to_asset', 'income_asset_value_increase_capital_account'].indexOf(transactionSubtype) > -1) {
          crAccount = entries.filter((el) => el.dctype === 'cr')
          transactionIncomeAmount = crAccount.length > 0 ? crAccount.map((el) => parseFloat(el.amount)) : []
          drAccount = entries.filter((el) => el.dctype === 'dr')
        } else {
          crAccount = entries.filter((el) => el.dctype === 'dr')
          transactionIncomeAmount = crAccount.length > 0 ? crAccount.map((el) => parseFloat(el.amount)) : []
          drAccount = entries.filter((el) => el.dctype === 'cr')
        }
        const passValues = {
          amount: transactionIncomeAmount.length > 0 ? transactionIncomeAmount.reduce((el, ac) => el + ac) : 0,
          accountMainValue: crAccount.length > 0 ? crAccount[0].account : null,
          accountOffsetValue: drAccount.length > 0 ? drAccount[0].account : null
        }
        if (transactionSubtype === 'income') {
          this.$nextTick(() => {
            this.$refs.TabGuideIncome.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'expense_debit') {
          this.$nextTick(() => {
            this.$refs.TabGuideExpenseDebit.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'expense_credit') {
          this.$nextTick(() => {
            this.$refs.TabGuideExpenseCredit.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'transfer_asset_to_asset') {
          this.$nextTick(() => {
            this.$refs.TabGuideTransferAssetToAsset.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'income_asset_value_increase_capital_account') {
          this.$nextTick(() => {
            this.$refs.TabGuideIncomeAssetValueIncreaseCapitalAccount.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'liability_amortization') {
          this.$nextTick(() => {
            this.$refs.TabGuideLiabilityAmortization.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'transfer_refund') {
          this.$nextTick(() => {
            this.$refs.TabGuideTransferRefund.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'transfer_networth_to_asset') {
          this.$nextTick(() => {
            this.$refs.TabGuideTransferNetworthToAsset.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'asset_value_decrease_networth_account') {
          this.$nextTick(() => {
            this.$refs.TabGuideAssetValueDecreaseNetworthAccount.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'asset_value_decrease') {
          this.$nextTick(() => {
            this.$refs.TabGuideAssetValueDecrease.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'transfer_liability_to_asset') {
          this.$nextTick(() => {
            this.$refs.TabGuideTransferLiabilityToAsset.setInitialData(passValues)
          })
          // start down here
        } else if (transactionSubtype === 'transfer_liability_to_liability') {
          this.$nextTick(() => {
            this.$refs.TabGuideTransferLiabilityToLiability.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'income_networth') {
          this.$nextTick(() => {
            this.$refs.TabGuideIncomeNetworth.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'expense_networth') {
          this.$nextTick(() => {
            this.$refs.TabGuideExpenseNetworth.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'liability_amortization_networth_account') {
          this.$nextTick(() => {
            this.$refs.TabGuideLiabilityAmortizationNetworth.setInitialData(passValues)
          })
        } else if (transactionSubtype === 'transfer_networth_to_liability') {
          this.$nextTick(() => {
            this.$refs.TabGuideTransferNetworthToLiability.setInitialData(passValues)
          })
        }
        this.$v.transaction_form.$touch()
      }
    },
    getTranslatedAccountOptions (options) {
      return options.map(accountOptions => {
        const type = this.$te('common.transaction_types.' + accountOptions.type.toLowerCase()) ? this.$t('common.transaction_types.' + accountOptions.type.toLowerCase()) : accountOptions.type
        const label = type + ' / ' + accountOptions.title
        return Object.assign(accountOptions, { label })
      })
    },
    onSuccessModalHide () {
      if (this.$route.params.transaction_id) {
        this.fetchTransactions()
      } else {
        this.fetchTransactions(this.transaction.id)
        this.$router.push({ name: 'transactions-create-single', params: { transaction_id: this.transaction.id } })
      }
    },
    onIncomeTabClick () {
      this.loadAccountOptions('income')
      this.loadOffestAccountOptions('asset')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'deposit'
      this.activeTab = 'income'
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'TabGuideIncome')) {
        this.$refs.TabGuideIncome.emit()
      }
    },
    onIncomeAssetValueIncreaseCapitalAccountTabClick () {
      this.loadAccountOptions('income_capital_accounts')
      this.loadOffestAccountOptions('asset')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'deposit'
      this.activeTab = 'income_asset_value_increase_capital_account'
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'TabGuideIncomeAssetValueIncreaseCapitalAccount')) {
        this.$refs.TabGuideIncomeAssetValueIncreaseCapitalAccount.emit()
      }
    },
    onIncomeToLiabilityTabClick () {
      this.loadAccountOptions('income')
      this.loadOffestAccountOptions('liability')
      this.transaction_form.type = 'deposit'
      this.activeTab = 'income_to_liability'
      if (Object.prototype.hasOwnProperty.call(this.$refs, 'TabGuideIncomeToLiability')) {
        this.$refs.TabGuideIncomeToLiability.emit()
      }
    },
    onAssetValueDecreaseNetworthAccountTabClick () {
      this.loadAccountOptions('asset')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'transfer'
      this.activeTab = 'asset_value_decrease_networth_account'
      this.$refs.TabGuideAssetValueDecreaseNetworthAccount.emit()
    },
    onAssetValueDecreaseTabClick () {
      this.loadAccountOptions('asset')
      // this.isTransactionTypeDropdownShown = false
      this.loadOffestAccountOptions('income_capital_accounts')
      this.transaction_form.type = 'transfer'
      this.activeTab = 'asset_value_decrease'
      this.$refs.TabGuideAssetValueDecrease.emit()
    },
    onExpenseCreditTabClick () {
      this.loadAccountOptions('expense')
      this.loadOffestAccountOptions('liability')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'withdrawal'
      this.activeTab = 'expense_credit'
      this.$refs.TabGuideExpenseCredit.emit()
    },
    onExpenseDebitTabClick () {
      this.loadAccountOptions('expense')
      this.loadOffestAccountOptions('asset')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'withdrawal'
      this.activeTab = 'expense_debit'
      this.$refs.TabGuideExpenseDebit.emit()
    },
    onExpenseNetworthTabClick () {
      this.loadAccountOptions('expense')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'withdrawal'
      this.activeTab = 'expense_networth'
      this.$refs.TabGuideExpenseNetworth.emit()
    },
    onIncomeNetworthTabClick () {
      this.loadAccountOptions('income')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'deposit'
      this.activeTab = 'income_networth'
      this.$refs.TabGuideIncomeNetworth.emit()
    },
    onLiabilityAmortizationTabClick () {
      this.loadAccountOptions('liability')
      this.loadOffestAccountOptions('asset,liability')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'transfer'
      this.activeTab = 'liability_amortization'
      this.$refs.TabGuideLiabilityAmortization.emit()
    },
    onLiabilityAmortizationNetworthTabClick () {
      // this.loadAccountOptions('liability')
      this.loadOffestAccountOptions('liability')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'transfer'
      this.activeTab = 'liability_amortization_networth_account'
      this.$refs.TabGuideLiabilityAmortizationNetworth.emit()
    },
    onTransferAssetToAssetTabClick () {
      this.loadAccountOptions('asset')
      this.loadOffestAccountOptions('asset')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'transfer'
      this.activeTab = 'transfer_asset_to_asset'
      this.$refs.TabGuideTransferAssetToAsset.emit()
    },
    onTransferLiabilityToLiabilityTabClick () {
      this.loadAccountOptions('liability')
      this.loadOffestAccountOptions('liability')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'transfer'
      this.activeTab = 'transfer_liability_to_liability'
      this.$refs.TabGuideTransferLiabilityToLiability.emit()
    },
    onTransferLiabilityToAssetTabClick () {
      this.loadAccountOptions('liability')
      this.loadOffestAccountOptions('asset')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'transfer'
      this.activeTab = 'transfer_liability_to_asset'
      this.$refs.TabGuideTransferLiabilityToAsset.emit()
    },
    onTransferNetworthToAssetTabClick () {
      this.loadAccountOptions('asset')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'transfer'
      this.activeTab = 'transfer_networth_to_asset'
      this.$refs.TabGuideTransferNetworthToAsset.emit()
    },
    onTransferNetworthToLiabilityTabClick () {
      this.loadAccountOptions('liability')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'transfer'
      this.activeTab = 'transfer_networth_to_liability'
      this.$refs.TabGuideTransferNetworthToLiability.emit()
    },
    onTransferRefundTabClick () {
      this.loadAccountOptions('expense')
      this.loadOffestAccountOptions('asset,liability')
      // this.isTransactionTypeDropdownShown = false
      this.transaction_form.type = 'withdrawal'
      this.activeTab = 'transfer_refund'
      this.$refs.TabGuideTransferRefund.emit()
    },
    onAdvancedTabClick () {
      // this.isTransactionTypeDropdownShown = false
      if (this.selectedGuideTab === 'complex_transaction') {
        this.transaction_form.type = 'transfer'
      }
      this.activeTab = 'advanced'
      this.$refs.TabAdvanced.emit()
    },
    onSubmit () {
      this.$v.form.$touch()
      if (this.$v.form.$anyError) {
        return false
      }
    },
    onTabInput (eventData, activeTab) {
      if (this.activeTab === activeTab) {
        this.entries = eventData
      }
    },
    onValidate (isValid, activeTab) {
      if (this.activeTab === activeTab) {
        this.isTabValid = isValid
      }

      if (activeTab === 'advanced') {
        this.isAdvancedTabBalanced = isValid
      }
    },
    onAdvancedEditModeChanged (isInEditMode) {
      this.isAdvancedTabEditMode = isInEditMode
    },
    saveTransactions () {
      this.$v.transaction_form.title.$touch()
      this.$v.selectedGuideTab.$touch()
      this.dirty = true
      if (!this.isTabValid || !this.isDetailsValid) {
        return false
      }

      let sDate = this.transaction_form.date.getFullYear() + '-'
      sDate += (this.transaction_form.date.getMonth() + 1 < 10 ? '0' : '') + (this.transaction_form.date.getMonth() + 1) + '-'
      sDate += (this.transaction_form.date.getDate() < 10 ? '0' : '') + this.transaction_form.date.getDate()
      const sendData = {
        transactions: [
          {
            title: this.transaction_form.title,
            description: this.transaction_form.description,
            date: sDate,
            type: this.transaction_form.type,
            entries: this.entries,
            verified: this.transaction_form.verified
          }
        ]
      }

      let apiMethod = 'post'
      if (Object.prototype.hasOwnProperty.call(this.transaction, 'id')) {
        sendData.transactions[0].id = this.transaction.id
        apiMethod = 'put'
      }

      axios({
        method: apiMethod,
        url: `${process.env.VUE_APP_ROOT_API}/transactions/with/entries`,
        data: sendData
      })
        .then((response) => {
          if (response.status === 201) {
            this.transaction.id = response.data.data[0].id
          }

          const putData = { tags: this.transaction_form.tags }
          axios.put(`${process.env.VUE_APP_ROOT_API}/transaction/tags/${this.transaction.id}`, putData)
            .then(response => {
              console.log(response.data.data)
            })
            .catch(err => {
              console.error(err)
            })

          this.$bvModal.show('successModal')
        })
        .catch((error) => {
          console.error(error)
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.update.failed_message').toString(), {
            title: this.$t(this.translationPath + 'toast.update.heading').toString(),
            variant: 'danger',
            solid: true,
            id: 'failedUpdate',
            autoHideDelay: 2000
          })
        })
    },
    async fetchTransactions (transactionId) {
      transactionId = transactionId || 0
      if (Object.prototype.hasOwnProperty.call(this.$route.params, 'transaction_id')) {
        transactionId = this.$route.params.transaction_id
      }
      if (!transactionId) {
        return false
      }

      try {
        const response = await axios.get(`${process.env.VUE_APP_ROOT_API}/transactions/${transactionId}`)
        const responseData = response.data.data

        const filterEnteries = Object.keys(responseData).indexOf('entries') > -1 ? responseData.entries.map((item, key) => {
          if (item.dctype === 'dr') {
            return Object.assign({}, item, { debitAmount: Number(item.amount), creditAmount: 0, index: key + 1, type: Object.keys(item.account).indexOf('type') > -1 ? item.account.type : null, description: item.description ? item.description : null })
          } else {
            return Object.assign({}, item, { debitAmount: 0, creditAmount: Number(item.amount), index: key + 1, type: Object.keys(item.account).indexOf('type') > -1 ? item.account.type : null, description: item.description ? item.description : null })
          }
        }) : []
        this.entries = Object.keys(responseData).indexOf('entries') > -1 ? responseData.entries.map((item, key) => {
          if (item.dctype === 'dr') {
            return Object.assign({}, item, { debitAmount: Number(item.amount), creditAmount: 0, index: key + 1, type: Object.keys(item.account).indexOf('type') > -1 ? item.account.type : null, description: item.description ? item.description : null })
          } else {
            return Object.assign({}, item, { debitAmount: 0, creditAmount: Number(item.amount), index: key + 1, type: Object.keys(item.account).indexOf('type') > -1 ? item.account.type : null, description: item.description ? item.description : null })
          }
        }) : []
        this.transaction = Object.assign({}, responseData, { entries: filterEnteries })
        this.transaction_form.title = this.transaction.title
        this.defineTabBySubtype()

        this.transaction_form.date = new Date(this.transaction.date)
        this.transaction_form.description = this.transaction.description
        this.transaction_form.verified = this.transaction.verified
      } catch (err) {
        this.$router.push('/transactions/overview')
        console.error(err)
      }
    },
    deleteCurrentTransaction () {
      axios.delete(`${process.env.VUE_APP_ROOT_API}/transactions/${this.transaction.id}`)
        .then(data => {
          this.$root.$bvToast.toast(this.$t(this.translationPath + 'toast.delete.success_message').toString(), {
            title: this.$t(this.translationPath + 'toast.delete.heading').toString(),
            variant: 'success',
            solid: true,
            'auto-hide-delay': 3000
          })
          this.$router.push('/transactions/view')
        })
        .catch(err => {
          console.error(err)
          this.$refs.singleDeleteTransModel.hide()
          this.$bvToast.toast(this.$t(this.translationPath + 'toast.delete.failed_message').toString(), {
            title: this.$t(this.translationPath + 'toast.delete.heading').toString(),
            variant: 'danger',
            solid: true,
            'auto-hide-delay': 3000
          })
        })
    },
    fetchAccountGroups (accountType) {
      let suffix = ''
      if (accountType === 'income_capital_accounts') {
        accountType = 'income'
        suffix = '&is_capital_account=1'
      }

      return axios.get(`${process.env.VUE_APP_ROOT_API}/accounts/groups/for/multiselect?account_type=${accountType}${suffix}&log=viewTransaction`)
        .then(response => {
          const optionArray = []
          const accountGroups = response.data.data
          if (this.$route.name === 'transactions-create') {
            accountGroups.map(group => {
              group.accounts = group.accounts.filter(account => account.status === 'active')
            })
          }
          Object.keys(accountGroups).forEach(function (key) {
            const allAccounts = accountGroups[key].accounts.map(accountItems => {
              return Object.assign(accountItems, {
                label: displayAccountTitle(accountItems.title, accountItems.code, this.currentCOA.prefix_account_title_with_code)
              })
            })
            optionArray.push({ title: displayAccountTitle(accountGroups[key].title, accountGroups[key].code, this.currentCOA.prefix_account_title_with_code), type: accountGroups[key].type, accounts: allAccounts })
          }.bind(this))
          return optionArray
        })
        .catch(error => {
          console.error(error)
          return []
        })
    },
    loadAccountOptions (accountTypes) {
      this.loadingMainAccounts = true
      this.accountType = null
      this.fetchAccountGroups(accountTypes)
        .then(data => {
          this.mainAccountOptions = data
        })
        .catch(err => {
          console.error(err)
          this.mainAccountOptions = []
        })
        .then(() => {
          this.loadingMainAccounts = false
        })
    },
    loadOffestAccountOptions (accountTypes) {
      this.loadingAccountOffsetOptions = true
      this.fetchAccountGroups(accountTypes)
        .then(data => {
          this.offsetAccountOptions = data
        })
        .catch(err => {
          console.error(err)
          this.offsetAccountOptions = []
        })
        .then(() => {
          this.loadingAccountOffsetOptions = false
        })
    },
    defineTabBySubtype () {
      if (!Object.prototype.hasOwnProperty.call(this.transaction, 'transaction_subtype')) {
        console.warn('No subtype property of transaction object. Guide tab cannot be defined.')
        return false
      }

      this.transaction_form.type = this.transaction.type
      this.selectedGuideTab = this.transaction.transaction_subtype
    },
    onMiscTabTransactionUpdated (transactionData) {
      this.transaction_form.verified = transactionData.verified
    },
    onMiscTabTagsUpdated (tags) {
      this.transaction_form.tags = tags
      console.log(this.transaction_form.tags)
    }
  },
  filters: {
    formatDate,
    displayAccountTitle
  },
  mounted () {
    this.sSuccessModalButtonText = this.$t('transactions.create.success_modal.button')
    if (Object.prototype.hasOwnProperty.call(this.$route.params, 'back_url')) {
      this.sSuccessModalRedirectUrl = this.$route.params.back_url
      this.sSuccessModalButtonText = this.$route.params.back_url_title
    }
  },
  watch: {
    '$route.params': function (val) {
      // eslint-disable-next-line camelcase
      const { transaction_id, clone } = val
      // eslint-disable-next-line camelcase
      if (transaction_id) {
        this.fetchTransactions()
      } else if (clone) {
        this.setInitialValuesOnType(clone)
      }
    },
    titleMeta: {
      handler () {
        this.setPageTitle(this.titleMeta)
      },
      immediate: true
    },
    'transaction_form.title' () {
      this.isDetailsValid = !this.$v.transaction_form.title.$error || !this.$v.selectedGuideTab.$error
    },
    'transaction_form.date' () {
      if (typeof this.transaction_form.date !== 'object') {
        this.transaction_form.date = new Date(this.transaction_form.date)
      }
      // this.transaction_form.date = this.transaction_form.date.toISOString().slice(0, 10)
    },
    selectedGuideTab () {
      if (this.onlyAdvanced || this.selectedGuideTab === 'complex_transaction') {
        setTimeout(() => {
          this.$refs.BTabAdvanced.activate()
          this.onAdvancedTabClick()
        }, 200)
        return true
      }

      setTimeout(() => {
        switch (this.selectedGuideTab) {
          case 'income':
            if (this.$refs.BTabGuideIncome) {
              this.$refs.BTabGuideIncome.activate()
              this.onIncomeTabClick()
            }
            break
          case 'income_asset_value_increase_capital_account':
            if (this.$refs.BTabGuideIncomeAssetValueIncreaseCapitalAccount) {
              this.$refs.BTabGuideIncomeAssetValueIncreaseCapitalAccount.activate()
              this.onIncomeAssetValueIncreaseCapitalAccountTabClick()
            }
            break
          case 'income_to_liability':
            if (this.$refs.BTabGuideIncomeToLiability) {
              this.$refs.BTabGuideIncomeToLiability.activate()
              this.onIncomeToLiabilityTabClick()
            }
            break
          case 'asset_value_decrease_networth_account':
            if (this.$refs.BTabGuideAssetValueDecreaseNetworthAccount) {
              this.$refs.BTabGuideAssetValueDecreaseNetworthAccount.activate()
              this.onAssetValueDecreaseNetworthAccountTabClick()
            }
            break
          case 'asset_value_decrease':
            if (this.$refs.BTabGuideAssetValueDecrease) {
              this.$refs.BTabGuideAssetValueDecrease.activate()
              this.onAssetValueDecreaseTabClick()
            }
            break
          case 'expense_credit':
            if (this.$refs.BTabGuideExpenseCredit) {
              this.$refs.BTabGuideExpenseCredit.activate()
              this.onExpenseCreditTabClick()
            }
            break
          case 'expense_debit':
            if (this.$refs.BTabGuideExpenseDebit) {
              this.$refs.BTabGuideExpenseDebit.activate()
              this.onExpenseDebitTabClick()
            }
            break
          case 'expense_networth':
            if (this.$refs.BTabGuideExpenseNetworth) {
              this.$refs.BTabGuideExpenseNetworth.activate()
              this.onExpenseNetworthTabClick()
            }
            break
          case 'income_networth':
            if (this.$refs.BTabGuideIncomeNetworth) {
              this.$refs.BTabGuideIncomeNetworth.activate()
              this.onIncomeNetworthTabClick()
            }
            break
          case 'liability_amortization':
            if (this.$refs.BTabGuideLiabilityAmortization) {
              this.$refs.BTabGuideLiabilityAmortization.activate()
              this.onLiabilityAmortizationTabClick()
            }
            break
          case 'liability_amortization_networth_account':
            if (this.$refs.BTabGuideLiabilityAmortizationNetworth) {
              this.$refs.BTabGuideLiabilityAmortizationNetworth.activate()
              this.onLiabilityAmortizationNetworthTabClick()
            }
            break
          case 'transfer_asset_to_asset':
            if (this.$refs.BTabGuideTransferAssetToAsset) {
              this.$refs.BTabGuideTransferAssetToAsset.activate()
              this.onTransferAssetToAssetTabClick()
            }
            break
          case 'transfer_liability_to_liability':
            if (this.$refs.BTabGuideTransferLiabilityToLiability) {
              this.$refs.BTabGuideTransferLiabilityToLiability.activate()
              this.onTransferLiabilityToLiabilityTabClick()
            }
            break
          case 'transfer_liability_to_asset':
            if (this.$refs.BTabGuideTransferLiabilityToAsset) {
              this.$refs.BTabGuideTransferLiabilityToAsset.activate()
              this.onTransferLiabilityToAssetTabClick()
            }
            break
          case 'transfer_networth_to_asset':
            if (this.$refs.BTabGuideTransferNetworthToAsset) {
              this.$refs.BTabGuideTransferNetworthToAsset.activate()
              this.onTransferNetworthToAssetTabClick()
            }
            break
          case 'transfer_networth_to_liability':
            if (this.$refs.BTabGuideTransferNetworthToLiability) {
              this.$refs.BTabGuideTransferNetworthToLiability.activate()
              this.onTransferNetworthToLiabilityTabClick()
            }
            break
          case 'transfer_refund':
            if (this.$refs.BTabGuideTransferRefund) {
              this.$refs.BTabGuideTransferRefund.activate()
              this.onTransferRefundTabClick()
            }
            break
        }
      }, 100)
    }
  },
  beforeRouteLeave (to, from, next) {
    this.transaction = {}
    this.transaction_form = {
      title: '',
      date: new Date(),
      description: '',
      type: ''
    }
    this.selectedGuideTab = null
    this.$v.$reset()
    next()
  }
}
</script>

<style lang="scss">
.reports-other-ledger-view {
  .nav-tabs .nav-link.active{background:white;border-bottom-color:white;}
  .input-date {
    background-color: #fff;
  }
  div.card{border:0;}
  .card-header{
    background: white;
    border:0;
    border-bottom:1px solid #CACACA;
  }
  #advancedTab___BV_tab_button__, #miscTab___BV_tab_button__ {
    color:#74788D;
  }
}
</style>
