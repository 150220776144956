<template>
  <div class="single-transactions-view-tab-misc">
    <p>{{ $t(translationPath + 'description') }}</p>
    <b-form-group
      :label="$t(translationPath + 'verified')"
      label-cols-md="3"
      label-class="font-weight-bold"
    >
      <b-form-checkbox
        v-model="isVerified"
        class="pt-2"
        value="1"
        unchecked-value="0"
        @input="onIsVerifiedInput"
      />
      <b-form-text>{{ $t(translationPath + 'verified_description') }}</b-form-text>
    </b-form-group>

    <b-form-group
      :label="$t(translationPath + 'tags')"
      label-cols-md="3"
      label-class="font-weight-bold"
      :state="$v.addTagName.$dirty ? !$v.addTagName.$invalid : null"
      :invalid-feedback="invalidAddTagNameFeedback"
    >
      <b-row no-gutters>
        <b-col cols="12" md="10">
          <autocomplete-input
            ref="TagInput"
            v-model="addTagName"
            :values="availableTags"
            :disabled="!isTagsLoaded"
            :state="$v.addTagName.$dirty ? !$v.addTagName.$invalid : null"
            :key="renderKey"
            class="mb-2"
          />
        </b-col>
        <b-col cols="12" md="2" class="text-right">
          <span class="btn btn-sm btn-primary mt-2 ml-1" @click="onAddTagClick">{{ $t('common.add') }}</span>
        </b-col>
      </b-row>
      <b-form-text>{{ $t(translationPath + 'tags_description') }}</b-form-text>
    </b-form-group>

    <b-form-group
      label=""
      label-cols-md="3"
      label-class="font-weight-bold"
    >
      <p class="mt-2">
        <span v-for="(tag, index) in tags" :key="index" class="tag-item mr-5">
          {{ tag }}
          <i
            class="flaticon solid trash-3 text-danger"
            :title="$t('common.delete')"
            @click="() => onTagDeleteClick(tag)"
          />
        </span>
      </p>
    </b-form-group>
  </div>
</template>

<script>
import axios from 'axios'
import AutocompleteInput from '@/components/common/AutocompleteInput'
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

export default {
  name: 'TabMisc',
  components: { AutocompleteInput },
  props: {
    transaction: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      translationPath: 'transactions.create.misc_tab.',
      isVerified: '0',
      isTagsLoaded: false,
      tags: [],
      availableTags: [],
      addTagName: '',
      renderKey: 0
    }
  },
  validations: {
    addTagName: { required, minLength: minLength(2), maxLength: maxLength(64) }
  },
  computed: {
    invalidAddTagNameFeedback () {
      if (this.$v.addTagName.required === false) return this.$t(this.translationPath + 'errors.tag_name_required')
      if (this.$v.addTagName.minLength === false) return this.$t(this.translationPath + 'errors.tag_name_min_length')
      if (this.$v.addTagName.maxLength === false) return this.$t(this.translationPath + 'errors.tag_name_max_length')
      return ''
    }
  },
  methods: {
    onIsVerifiedInput () {
      this.emitTransactionData()
    },
    emitTransactionData () {
      this.$emit('transaction-updated', { verified: this.isVerified })
    },
    emitTags () {
      this.$emit('tags-updated', this.tags)
    },
    fillDataFromTransaction () {
      this.isVerified = this.transaction.verified ? this.transaction.verified : '0'
      this.tags = []
      if (this.transaction.tags) {
        this.transaction.tags.map(tag => {
          this.tags.push(tag.title)
        })
      }
    },
    onTagDeleteClick (tag) {
      this.tags = this.tags.filter(t => t !== tag)
      this.emitTags()
    },
    loadAvailableTags () {
      this.isTagsLoaded = false
      axios.get(`${process.env.VUE_APP_ROOT_API}/tags`)
        .then(response => {
          response.data.data.map(tag => {
            this.availableTags.push(tag.title)
          })
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.isTagsLoaded = true
        })
    },
    onAddTagClick () {
      this.$v.addTagName.$touch()
      if (this.$v.addTagName.$invalid) {
        return false
      }

      if (this.tags.indexOf(this.addTagName) === -1) {
        this.tags.push(this.addTagName)
        this.emitTags()
      }
      this.addTagName = ''
      this.renderKey++
      this.$v.addTagName.$reset()
      process.nextTick(() => {
        this.$refs.TagInput.$focus()
      })
    }
  },
  mounted () {
    this.fillDataFromTransaction()
    this.loadAvailableTags()
  },
  watch: {
    transaction: {
      deep: true,
      handler () {
        this.fillDataFromTransaction()
      }
    }
  }
}
</script>

<style lang="scss">
.single-transactions-view-tab-misc {
  .tag-item {
    font-weight: normal;
  }
  .flaticon {
    cursor: pointer;
  }
}
</style>
