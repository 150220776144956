<template>
<b-container class="reports-other-ladger-tab-guide-expense">
  <i18n :path="translationPath+'intro_credit_p1'" tag="p" class="text-gray col-md-8 pl-0 pb-3">
    <b-link slot="read_more" to="#">{{$t(translationPath+'intro_p1_read_more_in_manual')}}</b-link>
  </i18n>
  <b-form-group
    :label="$t(translationPath + 'what_kind_of_expense')"
    label-cols-md="4"
    label-class="pt-3 font-weight-bold"
  >
    <custom-select
      :class="accountMainValidationCalss"
      :options="accountMainOptions"
      grouping-values="accounts"
      grouping-label="label"
      :loading="loadingAccountMainOptions"
      :label="'label'"
      v-model="accountMain"
      id="assetsSelection"
      :placeholder="$t('common.select_account')"
      :search-placeholder="$t('common.type_to_filter')"
      :noResultText="$t(translationPath + 'wizard.no_more_items')"
      @input="onDataInput"
      @select="onMainAccountChange"
    >
    </custom-select>
  </b-form-group>
  <b-form-group
    :label="$t(translationPath + 'label_amount')"
    label-cols-md="4"
    label-for="amountValue"
    label-class="pt-3 font-weight-bold"
  >
    <currency-input id="amountValue" v-model="amount" @input="onDataInput" />
  </b-form-group>

  <b-form-group
    :label="$t(translationPath + 'what_credit_did_you_use')"
    label-cols-md="4"
    label-class="pt-3 font-weight-bold"
  >
    <custom-select
      :class="accountOffsetValidationCalss"
      :options="accountOffsetOptions"
      grouping-values="accounts"
      grouping-label="label"
      :loading="loadingAccountOffsetOptions"
      :label="'label'"
      v-model="accountOffset"
      id="assetsSelection"
      :placeholder="$t('common.select_account')"
      :search-placeholder="$t('common.type_to_filter')"
      :noResultText="$t(translationPath + 'wizard.no_more_items')"
      @input="onDataInput"
    >
    </custom-select>
  </b-form-group>

  <b-form-group
    label-for="active_percentage"
    label-cols-md="4"
    label-class="pt-3 font-weight-bold"
  >
    <template slot="label">{{ $t(translationPath + 'label_percentage') }}</template>
    <template slot="description">
      {{ $t(translationPath + 'active_percentage_description', { active: activePercentage, passive: (100 - activePercentage) }) }}
      <b-link to="#">{{$t('common.read_more')}}</b-link>
    </template>

    <percentage-input
      v-model="activePercentage"
      slider
      :step="5"
      @input="onDataInput"
      :inputClass="activePercentageValidationClass"
    ></percentage-input>

  </b-form-group>

</b-container>
</template>

<script>
import { mapState } from 'vuex'
import CurrencyInput from '@/components/common/CurrencyInput'
import CustomSelect from '@/components/common/CustomSelect'
import PercentageInput from '@/components/common/PercentageInput'
import { required, minValue, maxValue } from 'vuelidate/lib/validators'

export default {
  name: 'TabGuideExpenseCredit',
  components: { CurrencyInput, CustomSelect, PercentageInput },
  props: {
    loadingAccountMainOptions: {
      type: Boolean,
      default: false
    },
    loadingAccountOffsetOptions: {
      type: Boolean,
      default: false
    },
    accountMainOptions: {
      type: Array,
      default: () => []
    },
    accountOffsetOptions: {
      type: Array,
      default: () => []
    },
    transaction: {
      type: Object,
      default: () => {}
    },
    highlightValidation: {
      type: Boolean,
      default: true
    }
  },
  validations: {
    amount: {
      required,
      minValue: minValue(0)
    },
    activePercentage: {
      required,
      minValue: minValue(0),
      maxValue: maxValue(100)
    },
    accountOffset: {
      required
    },
    accountMain: {
      required
    },
    form: ['amount', 'activePercentage', 'accountOffset', 'accountMain']
  },
  data () {
    return {
      translationPath: 'transactions.create.wizard.expense_credit_and_debit.',

      accountMain: null,
      accountOffset: null,
      activePercentage: 0,
      amount: 0,
      clonedData: false
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    finalEntries () {
      return [
        {
          account_id: this.accountMain ? this.accountMain.id : null,
          offset_account_id: this.accountOffset ? this.accountOffset.id : null,
          active_percentage: this.activePercentage,
          amount: this.amount,
          dctype: 'dr'
        },
        {
          account_id: this.accountOffset ? this.accountOffset.id : null,
          offset_account_id: this.accountMain ? this.accountMain.id : null,
          active_percentage: this.activePercentage,
          amount: this.amount,
          dctype: 'cr'
        }
      ]
    },
    amountValidationClass () {
      if (this.highlightValidation && this.$v.amount.$error) return 'is-invalid'
      return ''
    },
    activePercentageValidationClass () {
      if (this.highlightValidation && this.$v.activePercentage.$error) return 'is-invalid'
      return ''
    },
    accountMainValidationCalss () {
      if (this.highlightValidation && this.$v.accountMain.$error && !this.loadingAccountMainOptions) return 'is-invalid'
      return ''
    },
    accountOffsetValidationCalss () {
      if (this.highlightValidation && this.$v.accountOffset.$error && !this.loadingAccountOffsetOptions) return 'is-invalid'
      return ''
    }
  },
  methods: {
    setAccountMainValue (accountId) {
      this.accountMainOptions.forEach((group) => {
        group.accounts.forEach((account) => {
          if (account.id === accountId) {
            this.accountMain = account
          }
        })
      })
    },
    setAccountOffsetValue (accountId) {
      this.accountOffsetOptions.forEach((group) => {
        group.accounts.forEach((account) => {
          if (account.id === accountId) {
            this.accountOffset = account
          }
        })
      })
    },
    setInitialData (initSet = null) {
      this.clonedData = initSet || false
      if (this.clonedData) {
        this.amount = this.clonedData.amount
        if (this.clonedData.accountMainValue) {
          this.setAccountMainValue(this.clonedData.accountMainValue.id)
        }
        if (this.clonedData.accountOffsetValue) {
          this.setAccountOffsetValue(this.clonedData.accountOffsetValue.id)
        }
        this.emit()
      } else {
        if (Object.prototype.hasOwnProperty.call(this.transaction, 'entries') && this.transaction.entries[0].amount) {
          this.amount = parseFloat(this.transaction.entries[0].amount)
        }
        if (Object.prototype.hasOwnProperty.call(this.transaction, 'entries') && this.transaction.entries[0].active_percentage) {
          this.activePercentage = parseInt(this.transaction.entries[0].active_percentage)
        }
        this.setInitialDataAccount(true, true)
      }
    },
    setInitialDataAccount (isAccountMain, isAccountOffset) {
      if (isAccountMain) {
        this.accountMain = null
        this.$v.accountMain.$touch()
      }
      if (isAccountOffset) {
        this.accountOffset = null
        this.$v.accountOffset.$touch()
      }

      if (Object.prototype.hasOwnProperty.call(this.transaction, 'entries')) {
        this.transaction.entries.forEach((item) => {
          if (isAccountMain && item.dctype === 'dr' && Object.prototype.hasOwnProperty.call(item, 'account')) {
            this.setAccountMainValue(item.account_id)
          }
          if (isAccountOffset && item.dctype === 'cr' && Object.prototype.hasOwnProperty.call(item, 'account')) {
            this.setAccountOffsetValue(item.account_id)
          }
        })
      }
      this.emit()
    },
    onMainAccountChange () {
      process.nextTick(() => {
        this.activePercentage = this.accountMain ? this.accountMain.default_active_percentage : 0
      })
    },
    onDataInput () {
      this.$v.form.$touch()
      this.$emit('on-validate', !this.$v.form.$error)
      this.$emit('on-data-input', this.finalEntries)
    },
    emit () {
      this.onDataInput()
    }
  },
  created () {
    this.setInitialData()
  },
  watch: {
    loadingAccountMainOptions () {
      if (!this.clonedData) {
        this.setInitialDataAccount(true, false)
      } else {
        if (typeof this.clonedData.accountMainValue !== 'undefined' && this.clonedData.accountMainValue) {
          this.setAccountMainValue(this.clonedData.accountMainValue.id)
        }
      }
    },
    loadingAccountOffsetOptions () {
      if (!this.clonedData) {
        this.setInitialData(false, true)
      } else {
        if (typeof this.clonedData.accountOffsetValue !== 'undefined' && this.clonedData.accountOffsetValue) {
          this.setAccountOffsetValue(this.clonedData.accountOffsetValue.id)
        }
      }
    },
    transaction () {
      this.setInitialData()
    }
  }
}
</script>
