<template>
<b-container class="reports-other-ladger-tab-advanced pt-3">

  <p class="col-lg-10">{{ $t(translationPath + 'advanced_tab.description')}}</p>

  <b-table class="spirecta-simple-table view-transactions-advanced-tab-table mt-2 mb-0" show-empty hover responsive striped foot-clone
    ref="advancedTable"
    :items="entries"
    :fields="entriesFields"
    :emptyText="$t(translationPath + 'advanced_tab.table_empty_text')"
    stacked="lg"
  >

    <template v-slot:cell(account_type)="row">
      <template v-if="row.item.isInEdit">
        <b-form-group class="mb-0">
          <b-form-select :options="typeOptions" v-model="row.item.type" @change="(value) => onTypeChange(value, row.item)"></b-form-select>
        </b-form-group>
      </template>
      <template v-else>
        <div class="account_type">{{ $te('common.' + row.item.type) ? $t('common.' + row.item.type) : row.item.type }}</div>
      </template>
    </template>

    <template v-slot:cell(account_title)="row">
      <template v-if="row.item.isInEdit">
      <b-form-group class="mb-0">
        <table-multiselect-custom-view
          :placeHolder="$t('common.select_account')" :accounttype="row.item.type"
          selectedType="id" :selectedValue="row.item.account_id"
          @account-selected="(value) => onAccountSelected(value.accounts, row.item)"
          :class="row.item.account_id ? '' : 'is-invalid'"
          :disabled="!row.item.type">
        </table-multiselect-custom-view>
      </b-form-group>
      </template>
      <template v-else>
        <b-link :to="'/reports/performance/accounts/'+ row.item.account.id + '/view'">{{ $te(row.item.account.title) ? $t(row.item.account.title) : row.item.account.title | displayAccountTitle( row.item.account.code, currentCOA.prefix_account_title_with_code ) }}</b-link>
      </template>
    </template>

    <template v-slot:cell(entry_description)="row">
      <template v-if="row.item.isInEdit">
        <b-form-group
          class="mb-0"
        >
          <b-form-input
            v-model="row.item.description"
            :placeholder="$t('common.optional_placeholder')"
          />
        </b-form-group>
      </template>
      <template v-else>
        {{ row.item.description }}
      </template>
    </template>

    <template v-slot:cell(active_percentage)="row">
      <template v-if="row.item.isInEdit">
        <percentage-input v-model="row.item.active_percentage" :disabled="['asset', 'liability'].indexOf(row.item.type) > -1"></percentage-input>
      </template>
      <template v-else>
        {{ row.item.active_percentage ? row.item.active_percentage : 0 }} %
      </template>
    </template>

    <template v-slot:cell(debit_amount)="row">
      <div v-if="!loading">
      <template v-if="row.item.isInEdit">
        <b-form-group class="mb-0">
          <currency-input
            v-model="row.item.debitAmount"
            @input="() => { if (row.item.debitAmount) { row.item.dctype = 'dr' } }"
            :disabled="Boolean(row.item.creditAmount)"
          />
          </b-form-group>
      </template>
      <template v-else>
        <span v-if="row.item.dctype == 'dr' && currentCOA.locale">{{ row.item.debitAmount | formatAmount( currentCOA.locale, currentCOA.currency ) }}</span>
      </template>
      </div>
    </template>

    <template  v-slot:cell(credit_amount)="row">
      <div v-if="!loading">
      <template v-if="row.item.isInEdit">
        <b-form-group class="mb-0">
          <currency-input
            v-model="row.item.creditAmount"
            @input="() => { if (row.item.creditAmount) { row.item.dctype = 'cr' } }"
            :disabled="Boolean(row.item.debitAmount)"
          />
        </b-form-group>
        </template>
      <template v-else>
        <span v-if="row.item.dctype == 'cr' && currentCOA.locale">{{ row.item.creditAmount | formatAmount( currentCOA.locale, currentCOA.currency ) }}</span>
      </template>
      </div>
    </template>

    <!-- ROW BUTTONS -->
    <template v-slot:cell(actions)="row">
      <div class="">
        <button v-show="row.item.isInEdit" :disabled="!row.item.account_id" class='btn plain-btn text-regular' @click.stop="onSaveEntry(row.item)">
          <i class="flaticon stroke checkmark text-success"></i> {{ $t('common.save')}}
        </button>
        <button v-show="row.item.isInEdit" class='btn plain-btn text-regular' @click.stop="onCancelEntry(row.item)">
          <i class="fa fa-times text-warning"></i> {{ $t('common.cancel')}}
        </button>
        <button v-show="!row.item.isInEdit" class='btn plain-btn text-regular' @click.stop="onSwapEntry(row.item)">
          <i class="flaticon solid twin-arrows-1 text-primary"></i> {{ $t('common.swap')}}
        </button>
        <button v-show="!row.item.isInEdit" class='btn plain-btn text-regular' @click.stop="onEditEntry(row.item)">
          <i class="flaticon solid edit-3 text-primary"></i> {{ $t('common.edit')}}
        </button>
        <button class='btn plain-btn text-regular' v-show="!row.item.isInEdit" @click.stop="onDeleteEntry(row.item)">
          <i class="flaticon stroke trash-2 text-danger"></i> {{ $t('common.delete')}}
        </button>
      </div>
    </template>

    <template v-slot:head(title)="">
      <div class="">
        <div class="table-title-label"></div>
        <span class="table-title">{{ $t(translationPath + 'account_title') }}</span>
      </div>
    </template>
    <template v-slot:head(actions)="">
      <button class='btn btn-sm icon-btn text-regular my-2 btn-outline-secondary' @click.stop="onAddEntry">
        <i class="flaticon stroke plus"></i>
        {{ $t(translationPath + 'add_new_entry') }}
      </button>
      <button class='btn btn-sm btn-primary font-weight-bold add-new-row d-block d-sm-none'>
        <i class="flaticon stroke plus"></i>
      </button>
    </template>
    <template slot="bottom-row">
      <td colspan="3" class="text-right">{{ $t(translationPath + 'sum') }}:</td>
      <td class="text-right"><b v-if="!loading && currentCOA.locale">{{ debitTotal | formatAmount( currentCOA.locale, currentCOA.currency ) }}</b></td>
      <td class="text-right"><b v-if="!loading && currentCOA.locale">{{ creditTotal | formatAmount( currentCOA.locale, currentCOA.currency ) }}</b></td>
      <td>&nbsp;</td>
      <td>&nbsp;</td>
    </template>
    <template v-slot:foot(account_title)="">&nbsp;</template>
    <template v-slot:foot(account_type)="">&nbsp;</template>
    <template v-slot:foot(active_percentage)=""><div class="text-right">{{ $t(translationPath + 'difference') }}:</div></template>
    <template v-slot:foot(entry_description)="">&nbsp;</template>
    <template v-slot:foot(debit_amount)=""><span v-if="currentCOA.locale" :class="debitSumDiffState">{{ diffDebit | formatAmount( currentCOA.locale, currentCOA.currency )}}</span></template>
    <template v-slot:foot(credit_amount)=""><span v-if="currentCOA.locale" :class="creditSumDiffState">{{ diffCredit | formatAmount( currentCOA.locale, currentCOA.currency ) }}</span></template>
    <template v-slot:foot(actions)="">&nbsp;</template>
  </b-table>

  <delete-transaction-entry-modal
    ref="deleteEntryModal"
    @on-delete-confirm="deleteEntry"
    @on-delete-cancel="onDeleteCancel"
  ></delete-transaction-entry-modal>
</b-container>
</template>

<script>
import { mapState } from 'vuex'
import CurrencyInput from '@/components/common/CurrencyInput'
import formatAmount from '@/assets/filters/formatAmount'
import displayAccountTitle from '@/assets/filters/displayAccountTitle'
import DeleteTransactionEntryModal from '@/components/modals/DeleteTransactionEntryModal'
import PercentageInput from '@/components/common/PercentageInput'
import TableMultiselectCustomView from '@/components/common/TableMultiselectCustomView'

export default {
  name: 'TabAdvanced',
  components: { CurrencyInput, PercentageInput, TableMultiselectCustomView, DeleteTransactionEntryModal },
  filters: { formatAmount, displayAccountTitle },
  props: {
    transaction: {
      type: Object,
      default: () => {}
    },
    accountOptions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      translationPath: 'transactions.create.',
      entries: [],
      loading: false,
      entryToDelete: null,
      lastEntryIndex: 0
    }
  },
  computed: {
    ...mapState('user', ['currentUser', 'currentCOA']),
    entriesFields () {
      return [
        { key: 'account_type', label: this.$t(this.translationPath + 'account_type'), sortable: false, class: 'td-type' },
        { key: 'account_title', label: this.$t(this.translationPath + 'category'), sortable: false, class: 'td-title' },
        { key: 'active_percentage', label: this.$t('common.active_percentage'), sortable: false, class: 'text-left text-md-right td-percentage' },
        { key: 'debit_amount', label: this.$t(this.translationPath + 'debit_amount'), sortable: false, class: 'text-left text-md-right td-debit-amount' },
        { key: 'credit_amount', label: this.$t(this.translationPath + 'credit_amount'), sortable: false, class: 'text-left text-md-right td-credit-amount' },
        { key: 'entry_description', label: this.$t(this.translationPath + 'entry_description'), sortable: false, class: 'td-description' },
        { key: 'actions', class: 'd-lg-flex justify-content-lg-end align-items-center td-actions' }
      ]
    },
    typeOptions () {
      return [
        { value: 'income', text: this.$t('common.transaction_types.deposit') },
        { value: 'expense', text: this.$t('common.transaction_types.withdrawal') },
        { value: 'asset', text: this.$t('common.transaction_types.asset') },
        { value: 'liability', text: this.$t('common.transaction_types.liability') }
      ]
    },
    creditSumDiffState () { return this.diffCredit < 0 ? 'text-danger' : '' },
    debitSumDiffState () { return this.diffDebit < 0 ? 'text-danger' : '' },
    creditTotal () {
      if (!this.entries.length) return 0
      return this.entries.reduce(function (accum, item) {
        if (item.dctype === 'cr' && item.creditAmount === null) { // when empty row added to table
          return parseFloat(parseFloat(accum)).toFixed(2)
        }
        return parseFloat(parseFloat(accum) + (item.dctype === 'cr' ? parseFloat(item.creditAmount) : 0)).toFixed(2)
      }, 0.0)
    },
    debitTotal () {
      if (!this.entries.length) return 0
      return this.entries.reduce((accum, item) => parseFloat(parseFloat(accum) + (item.dctype === 'dr' ? parseFloat(item.debitAmount) : 0)).toFixed(2), 0)
    },
    diffCredit () {
      return (this.creditTotal - this.debitTotal) < 0 ? this.creditTotal - this.debitTotal : 0
    },
    diffDebit () {
      return (this.debitTotal - this.creditTotal) < 0 ? this.debitTotal - this.creditTotal : 0
    },
    finalEntries () {
      const final = []
      this.entries.forEach((item) => {
        const entry = {
          account_id: item.account_id,
          amount: item.debitAmount > item.creditAmount ? item.debitAmount : item.creditAmount,
          dctype: item.debitAmount > item.creditAmount ? 'dr' : 'cr',
          active_percentage: item.active_percentage,
          description: item.description
        }
        final.push(entry)
      })

      final.map((item) => {
        final.forEach((subitem) => {
          if (item.dctype !== subitem.dctype) {
            item.offset_account_id = subitem.account_id
          }
        })
        return item
      })

      return final
    }
  },
  methods: {
    emit () {
      this.$emit('on-data-input', this.finalEntries)
      this.$emit('on-validate', this.validate())
    },
    emitEditMode () {
      let editMode = false
      this.entries.map((item) => {
        if (item.isInEdit) {
          editMode = true
        }
      })
      this.$emit('on-edit-mode-changed', editMode)
    },
    setInitialData () {
      if (Object.prototype.hasOwnProperty.call(this.transaction, 'entries')) {
        this.entries = this.transaction.entries.map((item) => {
          item.isInEdit = false
          item.index = ++this.lastEntryIndex
          return item
        })
      }
    },
    validate () {
      if (this.diffDebit || this.diffCredit) return false
      if (!this.finalEntries.length) return false
      let validStatus = true
      this.finalEntries.forEach((item) => {
        if (!item.account_id) {
          validStatus = false
        }
      })
      return validStatus
    },
    deleteEntry () {
      this.entries = this.entries.filter((item) => item.index !== this.entryToDelete.index ? item : null)
      this.entryToDelete = null
      this.$refs.deleteEntryModal.hide()
    },
    onDeleteEntry (entry) {
      this.entryToDelete = entry
      this.$refs.deleteEntryModal.show()
    },
    onDeleteCancel () {
      this.entryToDelete = null
    },
    onEditEntry (entry) {
      entry.isInEdit = true
      entry.origin = Object.assign({}, entry)
      this.$refs.advancedTable.refresh()
      this.emitEditMode()
    },
    onSaveEntry (entry) {
      entry.isInEdit = false
      delete entry.origin
      this.$refs.advancedTable.refresh()
      this.emit()
      this.emitEditMode()
    },
    onCancelEntry (entry) {
      if (entry.type && entry.origin.type) {
        entry.isInEdit = false
        entry.type = entry.origin.type
        entry.account = entry.origin.account
        entry.account_id = entry.origin.account_id
        entry.description = entry.origin.description
        entry.active_percentage = entry.origin.active_percentage
        entry.debitAmount = entry.origin.debitAmount
        entry.creditAmount = entry.origin.creditAmount
        delete entry.origin
        this.$refs.advancedTable.refresh()
        this.emitEditMode()
      } else {
        this.entryToDelete = entry
        this.deleteEntry()
      }
    },
    onSwapEntry (entry) {
      const tmp = { cr: entry.creditAmount, dr: entry.debitAmount }
      entry.creditAmount = tmp.dr
      entry.debitAmount = tmp.cr
      entry.dctype = entry.dctype === 'cr' ? 'dr' : 'cr'
      this.onSaveEntry(entry)
    },
    onTypeChange (type, entry) {
      entry.account_id = 0
      entry.account = null
      if (['asset', 'liability'].indexOf(type) > -1) {
        entry.active_percentage = null
      }
    },
    onAccountSelected (account, entry) {
      entry.account_id = account.id
      entry.account = account
    },
    onAddEntry () {
      const emptyEntry = {
        id: 0,
        transaction_id: this.transaction.id,
        account_id: 0,
        offset_account_id: 0,
        amount: 0,
        description: null,
        active_percentage: 0,
        dctype: 'cr',
        account: {},
        debitAmount: null,
        creditAmount: null,
        type: null,
        isInEdit: true,
        index: ++this.lastEntryIndex
      }
      emptyEntry.origin = Object.assign({}, emptyEntry)
      this.entries.push(emptyEntry)
      this.emitEditMode()
    }
  },
  created () {
    this.setInitialData()
  },
  watch: {
    transaction () {
      this.setInitialData()
    },
    entries () {
      this.emit()
    }
  }
}
</script>

<style lang="scss">
.reports-other-ladger-tab-advanced{
  .ember-suggestions{
    min-width: 400px;
  }

  .view-transactions-advanced-tab-table
  {
    thead tr th{
      vertical-align: center;
      border-top:0;
    }

    @media screen and (min-width: 992px) and (max-width: 1072px) {
      td.td-percentage{width:50px}
      td.td-debit-amount{width:150px}
      td.td-credit-amount{width:150px}
      td.td-description{width:100px}
      td.td-actions{}
    }

    tbody tr:last-child td{
      border-top:1px solid black;
      border-bottom:2px solid black;
    }

    .b-table-empty-row {
      * {
        font-weight: 400;
      }
    }
  }
}
</style>
